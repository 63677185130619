import { IButtonAppearance, IButtonVariants } from 'components/button';
import { STYLES } from 'styles/style-variables';
import { Loader as LoaderCore, ILoaderProps as ILoaderPropsCore } from '../core/loader';

import { loaderTypes } from './loader-types-list';
import spinnerStyles from './spinner-styles.module.scss';

export interface ILoaderProps extends ILoaderPropsCore {
	buttonVariant?: IButtonVariants;
	butonAppearance?: IButtonAppearance;
}

const Loader = (props: ILoaderProps): React.JSX.Element => {
	switch (props.loaderType) {
		case loaderTypes.TWIN_LOADER:
			return (
				<LoaderCore
					{...props}
					primarySize="24px"
					secondarySize="24px"
					primaryColor={STYLES.COLOR_PRIMARY_BLUE}
					secondaryColor={STYLES.COLOR_SECONDARY_BLUE_FADED}
					propStyles={spinnerStyles}
				/>
			);
		case loaderTypes.BUTTON_LOADER:
			return (
				<LoaderCore
					{...props}
					primarySize="22px"
					primaryColor={getButtonLoaderColor(props.buttonVariant, props.butonAppearance)}
				/>
			);

		case loaderTypes.SPINNER_LOADER:
			return <LoaderCore {...props} primaryColor={STYLES.BUTTON_PRIMARY_BACKGROUND} />;
		default:
			return <></>;
	}
};

export { Loader };

const getButtonLoaderColor = (buttonVariant?: IButtonVariants, butonAppearance?: IButtonAppearance): string => {
	if (buttonVariant === 'primary') {
		switch (butonAppearance) {
			case 'filled':
				return 'white';
			case 'ghost':
			case 'outlined':
				return STYLES.COLOR_SECONDARY_BLUE;
			default:
				return 'white';
		}
	}

	if (buttonVariant === 'secondary') {
		switch (butonAppearance) {
			case 'filled':
				return STYLES.COLOR_PRIMARY_BLUE;
			case 'ghost':
			case 'outlined':
				return 'white';
			default:
				return STYLES.COLOR_PRIMARY_BLUE;
		}
	}

	return STYLES.COLOR_SECONDARY_BLUE;
};
