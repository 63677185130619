import React from 'react';
import clsx from 'clsx';
import { ParsedUrlQueryInput } from 'node:querystring';

import { NextLink } from 'components/next-link';
import { checkExternalUrl } from 'utils/check-external-url';
import { Loader } from 'components/loader';

import styles from './button-component.module.scss';
import { ButtonBase, IButtonTypes } from 'components/core/button-base';
import { DocReader } from 'components/docreader/docreader';

type IUrlQuery = string | null | ParsedUrlQueryInput | undefined;

export interface IButtonProps {
	disabled?: boolean;
	fullWidth?: boolean;
	href?: string;
	query?: IUrlQuery;
	icon?: React.ReactNode;
	iconPosition?: 'left' | 'right';
	isExternalLink?: boolean;
	isLoading?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	squared?: boolean;
	tabIndex?: number;
	targetBlank?: boolean;
	title?: string;
	type?: IButtonTypes;
	variant?: IButtonVariants;
	appearance?: IButtonAppearance;
	name?: string;
	ariaLabel?: string;
	squaredSize?: 'small' | 'medium' | 'big';
	className?: string;
	ariaExpanded?: boolean;
}

export type IButtonVariants =
	| 'primary'
	| 'primary-light'
	| 'secondary'
	| 'secondary-light'
	| 'secondary-light-no-shadow'
	| 'tertiary-light'
	| 'tertiary-dark';
export type IButtonAppearance = 'filled' | 'outlined' | 'ghost';
export type ILinkTargets = '_blank' | '_self';

const Button = (props: IButtonProps): React.JSX.Element => {
	const {
		fullWidth,
		href,
		query,
		icon,
		iconPosition = 'right',
		isExternalLink = false,
		onClick,
		squared = false,
		squaredSize,
		title = '',
		variant = 'primary',
		appearance = 'filled',
		disabled,
		className,
		ariaExpanded
	} = props;
	const targetBlank = props.targetBlank ? '_blank' : '_self';

	const docReaderVariants: IButtonVariants[] = ['tertiary-dark', 'tertiary-light'];

	return (
		<div className={styles.buttonWrapper}>
			<ButtonBase
				squared={squared}
				size={squaredSize}
				className={clsx(styles[variant], styles[appearance], className)}
				propStyles={styles}
				onClick={onClick}
				disabled={disabled || props.isLoading}
				link={renderLink(href, isExternalLink, targetBlank, query)}
				isNextJsLink={!checkExternalUrl(href)}
				linkTarget={targetBlank}
				fullWidth={fullWidth}
				loading={props.isLoading}
				type={props.type || 'button'}
				tabIndex={props.tabIndex}
				name={props.name}
				ariaLabel={props.ariaLabel}
				ariaExpanded={ariaExpanded}
			>
				<>
					{props.isLoading && renderLoading(variant, appearance)}
					{renderIcon(title, icon, iconPosition)}
				</>
			</ButtonBase>
			{docReaderVariants.includes(variant) && <DocReader className={styles.docReader} url={href || '/'} />}
		</div>
	);
};

const renderLoading = (variant: IButtonVariants, appearance: IButtonAppearance): React.JSX.Element => {
	return (
		<div className={styles.loader}>
			<Loader loaderType="buttonLoader" buttonVariant={variant} butonAppearance={appearance} />
		</div>
	);
};

const renderIcon = (title: string, icon?: React.ReactNode, position?: 'left' | 'right'): React.JSX.Element => {
	const titleElement = (className?: 'titleLeft' | 'titleRight'): React.JSX.Element => (
		<span className={clsx(styles.title, className && styles[className])}>{title}</span>
	);

	if (!icon) return titleElement();
	if (position === 'left' && title) {
		return (
			<>
				<span className={styles.icon}>{icon}</span>
				{titleElement('titleRight')}
			</>
		);
	}
	if (position === 'right' && title) {
		return (
			<>
				{titleElement('titleLeft')}
				<span className={styles.icon}>{icon}</span>
			</>
		);
	}

	return <span className={styles.icon}>{icon}</span>;
};

const renderLink = (
	href?: string,
	isExternalLink?: boolean,
	target?: ILinkTargets,
	query?: IUrlQuery
): React.JSX.Element | undefined => {
	if (!href) return undefined;

	if (isExternalLink || checkExternalUrl(href)) {
		return (
			<a role="button" href={href} target={target || '_blank'}>
				Ignoring this text
			</a>
		);
	}

	// Adds slash to start of URL to prevent Next routing issues
	const absoluteHref = href.startsWith('/') || href.startsWith('#') ? href : `/${href}`;
	// Removes starting slash when adding slash in template literal
	const relativeHref = href.startsWith('/') ? href.slice(1) : href;

	if (target === '_blank') {
		return (
			<a role="button" href={`${process.env.NEXT_PUBLIC_URL}/${relativeHref}`} target={target}>
				Ignoring this text
			</a>
		);
	}

	const nextLinkHref = query ? { query, pathname: absoluteHref } : absoluteHref;

	return <NextLink href={nextLinkHref} />;
};

export { Button };
