import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import { withStyling, IInjectedStylingProps } from './higher-order';

import styles from './button-base.module.scss';
import capitalize from 'lodash/capitalize';

export interface IButtonBaseProps extends IInjectedStylingProps, HTMLAttributes<HTMLElement> {
	borderRadius?: number;
	children: any;
	className?: string;
	disabled?: boolean;
	disableNoreferrer?: boolean;
	fullWidth?: boolean;
	link?: React.JSX.Element;
	linkTarget?: IButtonTargets;
	loading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	size?: 'small' | 'medium' | 'big';
	squared?: boolean;
	tabIndex?: number;
	tooltip?: string;
	tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
	type?: IButtonTypes;
	css?: any;
	name?: string;
	ariaLabel?: string;
	isNextJsLink?: boolean;
	ariaExpanded?: boolean | null;
}

export type IButtonTypes = 'submit' | 'reset' | 'button';
export type IButtonTargets = '_blank' | '_self';

const component = (props: IButtonBaseProps): React.JSX.Element => {
	const {
		children,
		fullWidth,
		link,
		size = props.size || 'medium',
		squared,
		type = props.type || 'button',
		disabled,
		disableNoreferrer,
		linkTarget,
		loading,
		className,
		tooltip,
		borderRadius,
		name,
		ariaExpanded = null,
		...rest
	} = props;

	const classNames = clsx(
		props.styling?.getStyle(styles, 'buttonBase'),
		className,
		size !== 'medium' && props.styling?.getStyle(styles, `size${capitalize(size)}`),
		fullWidth && props.styling?.getStyle(styles, 'fullWidth'),
		squared && props.styling?.getStyle(styles, 'squared'),
		loading && props.styling?.getStyle(styles, 'loading')
	);

	const renderLink = (): React.JSX.Element => {
		const extraLinkProperties = getExternalLinkProperties(link, disableNoreferrer, linkTarget);
		const anchorProps = {
			children,
			className: classNames,
			'data-tip': tooltip,
			...extraLinkProperties,
			...rest,
			styling: undefined
		};

		if (!link) {
			return <></>;
		}

		return <>{React.cloneElement(link, anchorProps)}</>;
	};

	const renderButton = (): React.JSX.Element => {
		const restWithoutStyling = { ...rest, styling: undefined };

		return (
			// eslint-disable-next-line react/button-has-type
			<button
				disabled={disabled}
				aria-disabled={disabled}
				type={type}
				className={classNames}
				onClick={props.onClick}
				tabIndex={props.tabIndex}
				data-tip={tooltip}
				style={{ borderRadius }}
				name={name}
				aria-label={props.ariaLabel}
				aria-expanded={ariaExpanded === null ? undefined : ariaExpanded}
				{...restWithoutStyling}
			>
				{children}
			</button>
		);
	};

	return (
		<>
			{tooltip && disabled ? (
				<span data-tip={tooltip}>{link ? renderLink() : renderButton()}</span>
			) : (
				<>{link ? renderLink() : renderButton()}</>
			)}
		</>
	);
};

const getExternalLinkProperties = (
	link?: React.JSX.Element,
	disableNoreferrer?: boolean,
	linkTarget?: IButtonTargets
): any => {
	if (link && link.type === 'a') {
		const noReferrerProps = { ...(!disableNoreferrer && { rel: 'noreferrer noopener' }) };
		const linkTargetProps = { ...(linkTarget && { target: linkTarget }) };

		return { ...noReferrerProps, ...linkTargetProps };
	}

	return {};
};

const ButtonBase = withStyling(component);
export { ButtonBase };
