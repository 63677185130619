/* eslint-disable react/self-closing-comp */
import React from 'react';
import { IInjectedStylingProps, withStyling } from './higher-order';
import { loaderTypes } from './loader-types-list';

import buttonLoaderStyling from './styles/button-loader.module.scss';
import twinLoaderStyling from './styles/twin-loader.module.scss';
import spinnerLoaderStyling from './styles/spinner-loader.module.scss';

export interface ILoaderProps extends IInjectedStylingProps {
	loaderType: 'buttonLoader' | 'spinnerLoader' | 'twinLoader';
	primarySize?: string;
	secondarySize?: string;
	primaryColor?: string;
	secondaryColor?: string;
}

const component = (props: ILoaderProps): React.JSX.Element => (
	<>
		{props.loaderType === loaderTypes.BUTTON_LOADER && (
			<div className={props.styling?.getStyle(buttonLoaderStyling, 'parent')}>
				<div className={props.styling?.getStyle(buttonLoaderStyling, 'spinner')}>
					<div
						style={{
							width: props.primarySize,
							height: props.primarySize,
							border: `2px solid ${props.primaryColor}`,
							borderTopColor: 'transparent'
						}}
					/>
				</div>
			</div>
		)}
		{props.loaderType === loaderTypes.SPINNER_LOADER && (
			<div className={props.styling?.getStyle(spinnerLoaderStyling, 'parent')}>
				<div className={props.styling?.getStyle(spinnerLoaderStyling, 'spinner')}>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
					<div
						style={{
							background: props.primaryColor
						}}
					/>
				</div>
			</div>
		)}
		{props.loaderType === loaderTypes.TWIN_LOADER && (
			<div className={props.styling?.getStyle(twinLoaderStyling, 'parent')}>
				<div className={props.styling?.getStyle(twinLoaderStyling, 'spinner')}>
					<div>
						<div>
							<div>
								<div
									style={{
										width: props.primarySize,
										height: props.primarySize,
										background: props.primaryColor
									}}
								/>
							</div>
						</div>
						<div>
							<div>
								<div
									style={{
										width: props.secondarySize,
										height: props.secondarySize,
										background: props.secondaryColor
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)}
	</>
);

const Loader = withStyling(component);

export { Loader };
