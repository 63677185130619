import { LightenDarkenColor } from 'utils/lighten-darken-color';

export const COLORS = {
	DISABLED_COLOR: '#aeaeae',
	GLOBAL_SECONDARY_BACKGROUND_COLOR: 'white',
	BUTTON_TERIARY_COLOR: '#0076a3'
};

export const STYLES = {
	COLOR_PRIMARY_BLUE: '#1B143C',
	COLOR_PRIMARY_YELLOW: '#D9E020',
	COLOR_SECONDARY_BLUE: '#005B7F',
	COLOR_SECONDARY_GREEN: '#48A842',
	COLOR_SECONDARY_YELLOW: '#FFDD00',
	COLOR_SECONDARY_BLUE_FADED: '#9DBACC',
	COLOR_TINT_GREY_100: '#000000',
	COLOR_TINT_GREY_80: '#ACACAC',
	COLOR_TINT_GREY_60: '#CFCFCF',
	COLOR_TINT_GREY_40: '#EBEBEB',
	COLOR_TINT_GREY_20: '#F3F4F6',
	COLOR_TINT_GREY_0: '#000000',
	COLOR_INFO_SUCCESS: '#22C683',
	COLOR_INFO_WARNING: '#FF8A00',
	COLOR_INFO_DANGER: '#D93C57',
	COLOR_INFO_DANGER_FADED: LightenDarkenColor('#D93C57', 20),
	COLOR_INFO_DANGER_DARKER: LightenDarkenColor('#D93C57', -10),
	COLOR_INFO_DANGER_DARKEST: LightenDarkenColor('#D93C57', -15),
	COLOR_BACKGROUND: '#F5F5F5',
	GLOBAL_BORDER_COLOR: '#E3E3E3',
	GLOBAL_BORDER_DARK_COLOR: '#CCCCCC',
	GLOBAL_BORDER_STYLE: '1px solid #E3E3E3',
	GLOBAL_BORDER_DARK_STYLE: '1px solid #CCCCCC',
	GLOBAL_SHADOW_STYLE: '0px 3px 0px #E3E3E3',
	GLOBAL_BORDER_RADIUS: '0px',
	FONT_COLOR_MAIN: '#000000',
	FONT_COLOR_BODY: '#333333',
	FONT_COLOR_FADED: '#6E6E6E',
	FONT_COLOR_PLACEHOLDER: '#AEAEAE',
	BUTTON_PRIMARY_BACKGROUND: '#0076A3',
	BUTTON_PRIMARY_BACKGROUND_HOVER: '#096082',
	BUTTON_PRIMARY_BACKGROUND_ACTIVE: '#0E4F68'
};
