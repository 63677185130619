import React from 'react';
import { Subtract } from 'utility-types';
import { StylingOverrideUtil, IStyles } from './styling';

interface IInjectedStylingProps {
	styling?: StylingOverrideUtil;
}

interface IWithStylingProps {
	propStyles?: IStyles;
}

interface IWithStylingState {
	styleUtil: StylingOverrideUtil;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const withStyling = <P extends IInjectedStylingProps>(Component: React.ComponentType<P>) =>
	class WithStyling extends React.Component<Subtract<P, IInjectedStylingProps> & IWithStylingProps, IWithStylingState> {
		public constructor(props: any) {
			super(props);

			this.state = {
				styleUtil: new StylingOverrideUtil(props.propStyles)
			};
		}

		public render(): React.JSX.Element {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { propStyles, ...rest } = this.props;
			return <Component {...(rest as unknown as P)} styling={this.state.styleUtil} />;
		}
	};

export { withStyling };
export type { IInjectedStylingProps, IWithStylingProps, IWithStylingState };
